<template>
    <!-- Page Content -->
    <div class="container" id="container">
      <div class="col-lg-12 mb-12 mt-5">
        <p class="text-left">研究対象者候補数が定員に達しましたので、募集受付を終了しました。</p>
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
}
</script>
<style scoped>
div#wallet{
  margin-top: 30px;
}
table{
  width: 40%;
  margin: 0 auto;
}
th{
  background-color: #007db9;
  color: #fff;
}
th, td{
  padding: 5px;
  border: thin solid rgba(0, 0, 0, 0.12);
}
#detail-btn button{
  cursor: pointer;
}
td {
  font-size:small;
}
</style>
